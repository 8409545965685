import React, { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  PatchPatientBodyBodiesGender,
  useGetPatientById,
  useGetPatientHook,
  usePatchPatient,
} from "api-client";
import { userState } from "atoms/userState";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useNavigates } from "hooks/useNavigates";
import { useSessionStorage } from "hooks/useSessionStorage";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useRecoilCallback } from "recoil";
import {
  UserRegisterSchema,
  UserRegisterSchemaType,
} from "schema/userRegister";
import { me } from "selectors/user";

import { Presenter } from "./Presenter";

export const UserRegister: React.FC = () => {
  const { navigateAddPaymentMethod, navigateBack } = useNavigates();
  const [value] = useLocalStorage("patientId");
  const [, setUserData] = useSessionStorage("user");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  //const patient = useRecoilValue(me);
  const { data: patient } = useGetPatientById(value || "");

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<UserRegisterSchemaType>({
    resolver: zodResolver(UserRegisterSchema),
    defaultValues: {
      name: patient?.name,
      nameKana: patient?.nameKana,
      email: localStorage.getItem("email") || "",
      postalCode: patient?.postalCode,
      prefecture: patient?.prefecture,
      address: patient?.address,
      phoneNumber: patient?.phoneNumber,
      gender: patient?.gender || ("MALE" as PatchPatientBodyBodiesGender),
      birthDay: patient?.birthDay,
    },
  });

  // patientの変更を監視してフォームの値を更新する
  useEffect(() => {
    if (patient) {
      setValue("name", patient.name || "");
      setValue("nameKana", patient.nameKana || "");
      setValue("postalCode", patient.postalCode || "");
      setValue("prefecture", patient.prefecture || "");
      setValue("address", patient.address || "");
      setValue("phoneNumber", patient.phoneNumber || "");
      setValue("gender", patient.gender || "MALE");
      setValue("birthDay", patient.birthDay || "1980-01-01");
    }
  }, [patient, setValue]);

  const watchValue = watch();

  const patchPatient = usePatchPatient({
    mutation: {
      onSuccess() {
        navigateAddPaymentMethod();
        setDisabled(false);
        setLoading(false);
      },
      onError(e) {
        const toastId = toast.error(`${e}`);
        setTimeout(() => {
          toast.dismiss(toastId);
        }, 2000);
        setDisabled(false);
        setLoading(false);
        return;
      },
    },
  });
  const refreshUserInfo = useRecoilCallback(
    ({ set }) =>
      async () => {
        const patient = useGetPatientHook();
        const me = patient();
        set(userState, await me);
      },
    [me]
  );

  const formSubmitHandler: SubmitHandler<UserRegisterSchemaType> = async (
    data
  ) => {
    if (!value) return;
    setDisabled(true);
    setLoading(true);

    await patchPatient.mutate({
      id: value,
      data: {
        ...data,
        finishedInitRegister: false,
        gender: data.gender as PatchPatientBodyBodiesGender,
      },
    });
    await refreshUserInfo();
    setUserData({
      name: data.name,
      nameKana: data.nameKana,
      email: data.email,
      postalCode: data.postalCode,
      prefecture: data.prefecture,
      address: data.address,
      phoneNumber: data.phoneNumber,
      gender: data.gender as PatchPatientBodyBodiesGender,
      birthDay: data.birthDay,
      finishedInitRegister: true,
    });
  };

  // MEMO: 必須項目が入力されていない場合はボタンを非活性に
  const isSubmitDisabled = !(watchValue.name && watchValue.nameKana && watchValue.birthDay && watchValue.gender && watchValue.postalCode && watchValue.prefecture && watchValue.address && watchValue.phoneNumber)
  return (
    <Presenter
      control={control}
      errors={errors}
      onSubmit={handleSubmit(formSubmitHandler)}
      goBack={navigateBack}
      disabled={disabled || isSubmitDisabled}
      loading={loading}
      setValue={setValue}
    />
  );
};
